if (module.hot) {
    module.hot.accept();
}

import $ from 'jquery';

window.$ = $
import 'bootstrap/dist/js/bootstrap'

$(document).ready(async () => {
    $('#open-login-modal').click(() => {
        $('#mobile-account-overlay').removeClass('is-active')
        $('.tab-pane').removeClass('active')
        $('#tab-sign-in').addClass('active')
        $('#login-modal').toggleClass('show')
        $('.modal-title').text('Giriş Yap')
    })
    $('#tab-show-sign-in').click(() => {
        $('.modal-title').text('Giriş Yap')
    })
    $('#tab-show-sign-up').click(() => {
        $('.modal-title').text('Kayıt Ol')
    })
    $('#login-overlay').click(() => {
        $('#login-modal').removeClass('show')
        $('.tab-pane').removeClass('active')
        $('#tab-sign-in').addClass('active')
        $('#mobile-account-overlay').removeClass('is-active')
    })
    $('.show-mobile-login').click(() => {
        $('#mobile-account-overlay').removeClass('is-active')
        $('.tab-pane').removeClass('active')
        $('#tab-sign-in').addClass('active')
        $('.modal-title').text('Kayıt Ol')
        $('#login-modal').toggleClass('show')
    })
    $('.show-mobile-register').click(() => {
        $('#mobile-account-overlay').removeClass('is-active')
        $('.tab-pane').removeClass('active')
        $('#tab-sign-up').toggleClass('active')
        $('.modal-title').text('Kayıt Ol')
        $('#login-modal').toggleClass('show')
    })
    $('#open-register-modal').click(() => {
        $('.tab-pane').removeClass('active')
        $('#tab-sign-up').toggleClass('active')
        $('#login-modal').toggleClass('show')
        $('.modal-title').text('Kayıt Ol')
    })
    $('.btn-close-modal').click(() => {
        $('#login-modal').removeClass('show')
    })
    $('.language-option').each((e, a) => {
        const el = $(a).children()
        const attr = el.attr('data-target-language')
        const opt = new Option(attr, attr)
        $('#language-selector').append(opt)
    });
    $('a[data-change-language]').click((e) => {
        $('#language-selector').click()
        $('ul[data-language-menu]').toggleClass('d-none')
    });
    $('a[data-target-language]').click((e) => {
        const language = $(e.target).attr('data-target-language')
        if (!language) return;
        $(`option[value=${language}]`).selected = true
        $('#language-selector').val(language)
        $('#active-language').html(e.target.innerHTML)
        $('ul[data-language-menu]').addClass('d-none')
        $('#language-form').submit()
    });
    $('#language-selector').change(() => {
        $('#language-form').submit()
    })
    $('.has-dropdown').click((e) => {
        console.log(e.currentTarget.classList)
        if (e.currentTarget.classList.contains('mobile-active')) {
            $('.has-dropdown').removeClass('mobile-active');
        } else {
            $('.has-dropdown').removeClass('mobile-active');
            $(e.currentTarget).addClass('mobile-active');
        }
        console.log(e.target)
    });
    $('#mobile-account-menu').click(() => {
        console.log('open!')
        $('#mobile-account-overlay').toggleClass('is-active')
    });
    $('#close-mobil-account-menu').click(() => {
        console.log('CLOSE!')
        $('#mobile-account-overlay').removeClass('is-active')
    })
    $('.accordion').click((e) => {
        if (e.currentTarget.classList.contains('is-active')) {
            $('.accordion').removeClass('is-active')
        } else {
            $('.accordion').removeClass('is-active')
            $(e.currentTarget).addClass('is-active')
        }
    })
    $('.video-tab').click((e) => {
        $('.video-tab').removeClass('active')
        $(e.currentTarget).addClass('active')
    })
    $('#clear-form').click((e) => {
        e.preventDefault()
        document.getElementById("contact-form").reset();
    })

    $('#other_country').keydown((e) => {

        if (e.target.value.length > 0) {
            $('#other').prop("checked", true);
            console.log('trigger')
        }

    })
    $('#other_industry').keydown((e) => {

        if (e.target.value.length > 0) {
            $('#other_industry_input').prop("checked", true);
            console.log('trigger')
        }

    })
    var navListItems = $('div.setup-panel div a'),
        allWells = $('.setup-content'),
        allNextBtn = $('.nextBtn'),
        sendBtn = $('#send-btn');

    allWells.hide();

    navListItems.click(function (e) {
        e.preventDefault();
        var $target = $($(this).attr('href')),
            $item = $(this);

        if (!$item.hasClass('disabled')) {
            navListItems.removeClass('btn-success').addClass('btn-default');
            $item.addClass('btn-success');
            allWells.hide();
            $target.show();
            $target.find('input:eq(0)').focus();
        }
    });


    allNextBtn.click(function () {
        var curStep = $(this).closest(".setup-content"),
            curStepBtn = curStep.attr("id"),
            nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
            curInputs = curStep.find("input[type='text'],input[type='url']"),
            isValid = true;

        console.log(curStepBtn)
        $(".form-group").removeClass("has-error");
        for (var i = 0; i < curInputs.length; i++) {
            if (!curInputs[i].validity.valid) {
                isValid = false;
                $(curInputs[i]).closest(".form-group").addClass("has-error");
            } else {
                $(curInputs[i]).closest(".form-group").removeClass("has-error");
            }
        }


        if (curStepBtn === 'step-1') {
            if ($('input[name="person_age"]:checked').length == 0 ||
                $('input[name="person_sex"]:checked').length == 0 ||
                $('input[name="person_education"]:checked').length == 0 ||
                $('input[name="person_country"]:checked').length == 0) {
                $('input[name]').closest(".form-group").addClass("has-error");
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return
            } else {
                $('input[name]').closest(".form-group").removeClass("has-error");
            }

            if($('#accept').prop("checked") == false){
                alert("KVKK FORMUNU OKUYUP ONAYLAMADINIZ.")
                return;
            }
        }
        if (curStepBtn === 'step-2') {
            if ($('input[name="person_industry"]:checked').length == 0 ||
                $('input[name="company_workers"]:checked').length == 0 ||
                $('input[name="company_location"]:checked').length == 0 ||
                $('input[name="company_build_type"]:checked').length == 0 ||
                $('input[name="company_danger_level"]:checked').length == 0) {
                $('input[name]').closest(".form-group").addClass("has-error");
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return
            } else {
                $('input[name]').closest(".form-group").removeClass("has-error");
            }
        }

        if (curStepBtn === 'step-3') {
            if (!($('input[name="question_17_answer_1"]').is(':checked')
                &&
                $('input[name="question_17_answer_2"]').is(':checked')
                &&
                $('input[name="question_17_answer_3"]').is(':checked')
                &&
                $('input[name="question_17_answer_4"]').is(':checked'))) {
                return;
            }

            if (!($('input[name="question_18_answer_1"]').is(':checked')
                &&
                $('input[name="question_18_answer_2"]').is(':checked')
                &&
                $('input[name="question_18_answer_3"]').is(':checked')
                &&
                $('input[name="question_18_answer_4"]').is(':checked'))) {
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return;
            }

            if (!($('input[name="question_20_answer_1"]').is(':checked')
                ||
                $('input[name="question_20_answer_2"]').is(':checked')
                ||
                $('input[name="question_20_answer_3"]').is(':checked')
                ||
                $('input[name="question_20_answer_4"]').is(':checked')
                ||
                $('input[name="question_20_answer_5"]').is(':checked')
                ||
                $('input[name="question_20_answer_6"]').is(':checked')
                ||
                $('input[name="question_20_answer_7"]').is(':checked')
            )
            ) {
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return;
            }
        }
        if (curStepBtn === 'step-4') {
            if (!($('input[name="question_21_answer_1"]').is(':checked')
                &&
                $('input[name="question_21_answer_2"]').is(':checked')
                &&
                $('input[name="question_21_answer_3"]').is(':checked')
                &&
                $('input[name="question_21_answer_4"]').is(':checked')
                &&
                $('input[name="question_21_answer_5"]').is(':checked')
                &&
                $('input[name="question_21_answer_6"]').is(':checked'))) {

                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return;
            }
            if ($('input[name="question_22_answer_1"]').is(':checked') || $('input[name="question_22_answer_2"]').is(':checked') || $('input[name="question_22_answer_3"]').is(':checked') || $('input[name="question_22_answer_4"]').is(':checked') || $('input[name="question_22_answer_5"]').is(':checked') || $('input[name="question_22_answer_6"]').is(':checked') || $('input[name="question_22_answer_7"]').is(':checked') || $('input[name="question_22_answer_8"]').is(':checked') || $('input[name="question_22_answer_9"]').is(':checked') || $('input[name="question_22_answer_10"]').is(':checked')) {
                var question22 = 1;
                $('.errorTxt22').html('');
            } else {
                alert('Lütfen tüm zorunlu alanları doldurunuz!')
            }
        }
        if (curStepBtn === 'step-5') {
            if (
                ($('input[name="question_21_answer_1"]').is(':checked')
                    && $('input[name="question_21_answer_2"]').is(':checked')
                    && $('input[name="question_21_answer_3"]').is(':checked')
                    && $('input[name="question_21_answer_4"]').is(':checked')
                    && $('input[name="question_21_answer_5"]').is(':checked')
                    && $('input[name="question_21_answer_6"]').is(':checked'))
            ) {
                $('.errorTxt21').html('');
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return;
            }
            if (
                ($('input[name="question_26_answer_1"]').is(':checked')
                    && $('input[name="question_26_answer_2"]').is(':checked')
                    && $('input[name="question_26_answer_3"]').is(':checked')
                    && $('input[name="question_26_answer_4"]').is(':checked')
                    && $('input[name="question_26_answer_5"]').is(':checked')
                    && $('input[name="question_26_answer_6"]').is(':checked'))
            ) {
                $('.errorTxt21').html('');
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return;
            }

            //question22
            if (
                !($('input[name="question_22_answer_1"]').is(':checked')
                    || $('input[name="question_22_answer_2"]').is(':checked')
                    || $('input[name="question_22_answer_3"]').is(':checked')
                    || $('input[name="question_22_answer_4"]').is(':checked')
                    || $('input[name="question_22_answer_5"]').is(':checked')
                    || $('input[name="question_22_answer_6"]').is(':checked')
                    || $('input[name="question_22_answer_7"]').is(':checked')
                    || $('input[name="question_22_answer_8"]').is(':checked')
                    || $('input[name="question_22_answer_9"]').is(':checked')
                    || $('input[name="question_22_answer_10"]').is(':checked'))
            ) {
                alert('Lütfen tüm zorunlu alanları doldurunuz.')
                return;
            }
        }

        if (isValid) nextStepWizard.removeAttr('disabled').trigger('click');
    });
    sendBtn.click((e) => {
        var curStep = $(".setup-content");
        $(curStep).each((idx, step) => {
            var curStepBtn = $(step).attr("id"),
                nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
                curInputs = $(step).find("input[type='text'],input[type='url']"),
                isValid = true;
            $(".form-group").removeClass("has-error");
            for (var i = 0; i < curInputs.length; i++) {
                if (!curInputs[i].validity.valid) {
                    isValid = false;
                    $(curInputs[i]).closest(".form-group").addClass("has-error");


                    alert("Bir veya daha fazla zorunlu soruyu boş bıraktınız.")
                    e.preventDefault();
                    return
                } else {
                    $(curInputs[i]).closest(".form-group").removeClass("has-error");
                }
            }

        })
    })

    $('div.setup-panel div a.btn-success').trigger('click');
});
